import http from '@http';
import toResponse from '@helpers/toResponse/toResponse';

export const sendPaymentData = (values) => toResponse(http.post('/payments', values));
// { params: { filter: 'svod' } }
export const getSvodPrices = async ({ params }) => {
  const { data } = await http.get(`/payments/public`, { params });
  return data;
};

// { params: { filter: 'deposit' } }
export const getDepositPrices = async ({ params }) => {
  const { data } = await http.get(`/payments`, { params });
  return data;
};

export const getOnlyDepositPrices = async (queryKey, _data) => {
  const { data } = await http.get(`/deposit-prices`, {
    params: {
      filter: 'deposit'
    },
  });
  return data;
};

export const getDepositPricesPromoCode = async (promoCode) => {
  const { data } = await http.get(`/deposit-prices`, {
    params: {
      filter: 'deposit',
      promoCode
    },
  });
  return data;
};

export const getOnlySvodPrices = async (queryKey) => {
  const { data } = await http.get(`/deposit-prices`, { params: { filter: 'svod' } });
  return data;
};

export const getSvodSpecialoffer = async (queryKey) => {
  const { data } = await http.get(`/payments/public`, { params: { filter: 'offer', epoch_id: 'cuecac42p1455744'} });//epoch_id: 'cuecac41p1455363'} });
  return data;
};


export const confirmPaymentRedirect = (values) => toResponse(http.get('/payments/fetch', { params: values }));

export const savedCardCharge = (values) => toResponse(http.post('/payments/charge', values));

export const sendEpochResults = (params) => toResponse(http.post(`/payments/postback/epoch${params}`));

export const buyStreamMovies = (values) => toResponse(http.post(`/buy`, values));

export const abortPayment = () => toResponse(http.get('/payments/cancel'));
export const testPayments = (values) => toResponse(http.post('/test-payments', values));


export const failurePayment = (values) => toResponse(http.post('/payments/failure', values));
